import icaoEnCountries from '../constants/countries/icao-other-codes-to-en_US.json'
import icaoFrCountries from '../constants/countries/icao-other-codes-to-fr_FR.json'
import iso3ToIso2 from '~/constants/countries/countries-iso3-to-iso2.json'
import enUS from '~/constants/countries/alphacode-countries-en_US.json'
import frFR from '~/constants/countries/alphacode-countries-fr_FR.json'

export const getCountryName = (alphaCode, locale) => {
  const alpha2code = alphaCode in iso3ToIso2 ? iso3ToIso2[alphaCode] : alphaCode
  if (locale === 'en' && alpha2code in enUS) {
    return enUS[alpha2code]
  }
  if (locale === 'fr' && alpha2code in frFR) {
    return frFR[alpha2code]
  }
  return null
}

/**
 * Returns the corresponding country name from readable passports documents' ICAO trigram
 * @param {*} icaoTrigram
 * @param {*} locale
 * @returns the full country name corresponding to the trigram
 */
export const getICAOCountryName = (icaoTrigram, locale) => {
  let alphaCodeIso2 = ''
  let localizedCountryName = locale === 'en' ? 'Non defined' : 'Non renseigné'
  if (icaoTrigram in iso3ToIso2) {
    alphaCodeIso2 = iso3ToIso2[icaoTrigram]
    localizedCountryName =
      locale === 'en' ? enUS[alphaCodeIso2] : frFR[alphaCodeIso2]
  } else if (icaoTrigram in icaoEnCountries) {
    localizedCountryName =
      locale === 'en'
        ? icaoEnCountries[icaoTrigram]
        : icaoFrCountries[icaoTrigram]
  }
  return localizedCountryName
}
